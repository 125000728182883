import React from "react";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Text } from "@atoms";
import SearchItem from "./SearchItem";

const SearchResults = ({ term, results, searching }) => {
  const lang = useLang();
  return (
    <div className="flex flex-col gap-8">
      {!term.length && (
        <Text variant="h4">
          {t("Enter a search term above to see results.", lang)}
        </Text>
      )}
      {!!term.length && searching && !results.length && (
        <Text variant="h4">{t("Searching...", lang)}</Text>
      )}
      {!!term.length && !searching && !results.length && (
        <div className="flex flex-col gap-4">
          <Text variant="h4">
            {t("Sorry, your search returned no results.", lang)}
          </Text>
          <Text variant="lg">
            {t("Try modifying your search terms and try again.", lang)}
          </Text>
        </div>
      )}
      {!!term.length &&
        !searching &&
        !!results.length &&
        results.map(result => {
          return <SearchItem key={result.uid} {...result} />;
        })}
    </div>
  );
};

SearchResults.defaultProps = {};

export default SearchResults;

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { useAppState } from "@state";
import debounce from "lodash.debounce";
import algoliasearch from "algoliasearch";
import { Icon } from "@atoms";
import SearchResults from "./SearchResults";

const siteClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_SITE_APP_ID,
  process.env.GATSBY_ALGOLIA_SITE_SEARCH_KEY
);
const siteIndex = siteClient.initIndex(process.env.GATSBY_ALGOLIA_SITE_INDEX);

const debounceFunc = debounce((q, s) => s(q), 500);

const SearchModal = () => {
  const [{ modal: modalState }, dispatch] = useAppState();
  const { modal: open } = modalState;
  const [term, setTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const lang = useLang();

  const doSearch = async q => {
    try {
      const _search = await siteIndex.search(q, {
        attributesToRetrieve: ["*"],
        hitsPerPage: 20,
        page: 0,
        attributesToSnippet: "*:20",
        snippetEllipsisText: "…",
        responseFields: "*",
        explain: "*",
        maxValuesPerFacet: 100,
      });
      const formattedResults = _search?.hits?.map(hit => {
        return {
          uid: hit.objectId || hit.pathname,
          title: hit.title,
          description: hit.description,
          image: { url: hit.image?.replace(/\s/g, ""), skipBuild: true },
          url: hit.url,
        };
      });
      setResults(formattedResults);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setSearching(false);
  };

  useEffect(() => {
    if (term.length) {
      setSearching(true);
      debounceFunc(term, doSearch);
    }
  }, [term]);

  return (
    <div
      className={classNames(
        "relative flex w-full flex-col gap-8 bg-white p-8 shadow-lg md:p-16",
        {
          "pointer-events-auto": open,
        }
      )}
    >
      <button
        type="button"
        className="absolute right-0 top-0 z-30 flex items-center p-4 text-black duration-300 hover:text-green"
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        aria-label="Close modal"
      >
        <Icon name="close" className="h-6 w-6" />
      </button>
      <div className="flex w-full justify-center">
        <input
          type="text"
          value={term}
          placeholder={t("Search the site...", lang)}
          onChange={e => setTerm(e.currentTarget.value)}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className="w-full border-b-2 border-black py-1 pr-6 placeholder-gray-dark"
        />
      </div>
      <div
        className={classNames("relative duration-300", {
          "pointer-events-none": searching,
        })}
      >
        <SearchResults term={term} results={results} searching={searching} />
      </div>
    </div>
  );
};

export default SearchModal;
